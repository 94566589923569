import React, { useEffect } from "react"
const loadText = require("src/utils").loadText
import Footer from "../../components/footer"
import Drop from "../../components/drop"
import SEO from "../../components/SEO"
import Form from "../../components/form"
import { sendData } from "../../components/form-biz"
import {
  MapContainer,
  Solution,
  IconGroups,
} from "../../pages/products/bare-metal-cloud"
import Button from "../../components/ui/button"
import { navigate, Link } from "gatsby"
import { Benefits } from "../../pages/products/cloud-networking"
import "../../styles/lp-sdn.less"
const url_data = loadText("url")
const static_data = loadText("lp-sdn")
const KV = () => {
  return (
    <div className="lp-sdn-kv">
      <div className="lp-sdn-kv-element">
        <div className="lp-sdn-kv-bg">
          <div className="lp-sdn-content">
            <div className="lp-sdn-kv-top">
              <Link to="/">
                <img src="/lp-sdn/logo.svg" alt="logo" />
              </Link>
              <div className="lp-sdn-kv-description">
                {static_data.topBanner.description}
              </div>
            </div>
            <div className="lp-sdn-kv-title">{static_data.topBanner.title}</div>
            <div className="lp-sdn-kv-subtitle">
              {static_data.topBanner.subtitle}
            </div>
            <div className="lp-sdn-kv-feature">
              {static_data.topBanner.features.map((content, i) => (
                <div className="lp-sdn-kv-feature-item">
                  <img src={`/lp-sdn/kv-f${i + 1}.svg`} />
                  <div>{content}</div>
                </div>
              ))}
            </div>
            <div className="lp-sdn-btnRow">
              <Button
                width={424}
                className="white"
                size="l"
                onClick={() => {
                  window.open(
                    `https://console.zenlayer.com/auth/signup?utm_source=lp-sdn&utm_medium=cpc&utm_campaign=sdn&referral=${encodeURIComponent(
                      `${location.origin}/${location.pathname}`
                    )}`
                  )
                }}
              >
                Self-deploy Now
              </Button>
            </div>
            <div className="lp-sdn-form-title">{static_data.form.title}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
const Feature = () => {
  const bg = [
    "linear-gradient(180deg, #F2FDFF 0%, #E0F9FF 100%)",
    "linear-gradient(148deg, #FBF6FF 0%, #F4E6FF 100%)",
    "linear-gradient(143deg, #FFFDF2 0%, #FFF9E1 100%)",
  ]
  return (
    <div className="lp-sdn-feature">
      {static_data.features.map(({ title, description }, idx) => (
        <div
          className="lp-sdn-feature-item-wrapper"
          style={{ background: bg[idx] }}
        >
          <div
            style={{ backgroundImage: `url("/lp-sdn/kf-${idx + 1}.svg")` }}
            className="lp-sdn-feature-item"
          >
            <div className="lp-sdn-feature-item-title">{title}</div>
            <div className="lp-sdn-feature-item-description">{description}</div>
          </div>
        </div>
      ))}
    </div>
  )
}
const Usecase = () => {
  return (
    <div className="lp-sdn-usecase">
      <Drop color="#fff" bgColor="rgb(6,135,246)" />

      <div className="lp-sdn-usecase-element">
        <div className="lp-sdn-usecase-title">{static_data.usecase.title}</div>
        <div className="lp-sdn-usecase-subtitle">
          {static_data.usecase.subtitle}
        </div>
        <div className="lp-sdn-content">
          <div className="lp-sdn-usecase-content">
            {static_data.usecase.content.map(({ title, description }, idx) => (
              <div className="lp-sdn-usecase-item">
                <img src={`/lp-sdn/usecase-${idx + 1}.svg`} alt={title} />
                <div className="lp-sdn-usecase-item-content">
                  <div className="lp-sdn-usecase-item-title">{title}</div>
                  <div className="lp-sdn-usecase-item-description">
                    {description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
const Testimony = () => {
  return (
    <div className="lp-sdn-testimony">
      <div className="lp-sdn-testimony-element">
        <div className="lp-sdn-testimony-content-wrapper">
          <img
            className="lp-sdn-testimony-pic"
            src="/lp-sdn/testimony-pic.png"
            alt="testimony-picture"
          ></img>
          <div className="lp-sdn-testimony-content">
            <div className="lp-sdn-testimony-description">
              {static_data.testimony.content}
            </div>
            <img
              src="/lp-sdn/testimony-signature.svg"
              alt="signature"
              className="lp-sdn-testimony-signature"
            />
            <div className="lp-sdn-testimony-jobTitle">
              {static_data.testimony.jobTitle}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default function Index() {
  // useEffect(() => {
  //   loadScriptToHead(
  //     " (function(){ window.ldfdr = window.ldfdr || {}; (function(d, s, ss, fs){ fs = d.getElementsByTagName(s)[0]; function ce(src){ var cs  = d.createElement(s); cs.src = src; setTimeout(function(){fs.parentNode.insertBefore(cs,fs)}, 1); } ce(ss); })(document, 'script', 'https://sc.lfeeder.com/lftracker_v1_p1e024B62oW4GB6d.js'); })(); "
  //   )
  // }, [])
  return (
    <div className="lp-sdn">
      <SEO {...static_data.seo} featuredImage="/featuredImage/sdn.jpg" />
      <KV />
      <div className="lp-sdn-form-wrapper">
        <div className="lp-sdn-form lp-sdn-content">
          <Drop color="rgb(9,125,229)" bgColor="#fff" />
          <Form
            type="sdnLp"
            onSuccessWithoutModal={() => {
              navigate("/lp/thanks")
            }}
            onSubmit={async data => {
              await sendData({
                data,
                url: "https://go.zenlayer.com/l/879902/2021-04-19/3v5dj",
                source: "Cloud Networking Landing Page",
              })
            }}
          />
        </div>
      </div>
      <Feature />
      <div className="bmc-keyFeatures-block3">
        <IconGroups />
      </div>
      <Benefits />
      <Usecase />
      <Testimony />
      <div>
        <Drop color="rgb(210,243,254)" bgColor="#f5f9ff" />
        <Solution
          title={static_data.solution.title}
          description={static_data.solution.description}
          hideMap={{
            sdn: true,
            sdwan: true,
            edcs: true,
          }}
        />
      </div>
      <MapContainer
        title={static_data.map.title}
        description={static_data.map.description}
        defaultSelected={[url_data.sdn.name]}
      />

      <Footer />
    </div>
  )
}
